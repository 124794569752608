import React from "react";
import Layout from "../components/layout";
import "../styles/policy.css";
import SEO from "../components/seo";

export default function Policy(props) {

    return (
        <Layout location={props.location}>
            <SEO title="Политика защиты персональной информации пользователей сайтов" />
            <section className="policy">
                <div className="container">
                    <h4 className="policy__title">Политика защиты персональной информации пользователей сайтов <span className="policy__title_br">strekoza.ai</span></h4>
                    <p>Настоящая политика конфиденциальности персональных данных (далее - Политика
                        конфиденциальности) действует в отношении всей информации, которую Администрация сайта может
                        получить о пользователе во время использования сайта <a href="https://strekoza.ai" target="_blank" rel="noopener noreferrer">https://strekoza.ai</a> (далее - Сайт).
                    </p>
                    <p>Администрация сайта соблюдает конфиденциальность пользователей ее продуктов и услуг, а также
                        положения действующих законов о защите частной жизни, в том числе, Федерального закона №152-ФЗ
                        «О персональных данных» для Российской Федерации и Общеевропейского регламента о защите
                        данных (GeneralDataProtectionRegulation, GDPR) для Европейского Союза. Настоящая Политика
                        конфиденциальности регулируется правом Российской Федерации, а для жителей Европейской
                        экономической зоны - Общеевропейским регламентом о защите данных
                        (GeneralDataProtectionRegulation, GDPR).</p>
                    <h5 className="policy__head">1. Основные понятия</h5>
                    <p>В настоящей Политике конфиденциальности используются следующие термины и понятия:</p>
                    <p><span className="policy__term">«АДМИНИСТРАЦИЯ САЙТА»</span> (далее - Администрация сайта) - уполномоченные сотрудники на
                        управления сайтами strekoza.ai, которые организуют и (или) осуществляет обработку персональных данных, а также
                        определяет цели обработки персональных данных, состав персональных данных, подлежащих
                        обработке, действия (операции), совершаемые с персональными данными.</p>
                    <p><span className="policy__term">«ПЕРСОНАЛЬНЫЕ ДАННЫЕ»</span> - любая информация, относящаяся к определенному или
                        определяемомуна основании такой информации физическому/юридическому лицу, которое использует
                        сайт. Кроме того, к персональным данным относятся данные, которые автоматически передаются сайту в
                        процессе их использования с помощью установленного на устройстве Пользователя программного
                        обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или
                        иной программе, с помощью которой осуществляется доступ к сайту).
                    </p>
                    <p><span className="policy__term">«ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ»</span> сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование,
                        распространение (в том числе передача), обезличивание, блокирование, уничтожение персональных данных Пользователя.</p>
                    <p><span className="policy__term">«ПОЛЬЗОВАТЕЛЬ (ВЫ)»</span> - лицо, которое использует сайт в порядке, регламентированном Администрацией сайта.</p>
                    <h5 className="policy__head">2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
                    <p>2.1 Администрация сайта осуществляет обработку Персональных данных для следующих целей:</p>
                    <p>а) когда это необходимо для исполнения соглашения с Вами, чтобы обеспечивать
                        полнофункциональный сервис и предоставлять соответствующий контент и услуги;
                    </p>
                    <p>б) Предоставления обратной связи по вопросам работы платформы;</p>
                    <p>в) Предоставление лицензии на использование платформы и оказание услуг, связанных с использованием платформы, в том числе создание личного кабинета на платформе.</p>
                    <p>2.2 Администрация сайта осуществляет сбор и обработку Персональных данных по следующим основаниям:</p>
                    <p>а) когда это необходимо для исполнения соглашения с Вами, чтобы обеспечивать полнофункциональный сервис и предоставлять соответствующий контент и услуги;</p>
                    <p>б) когда это необходимо для соблюдения надлежащих правовых обязательств;</p>
                    <p>в) когда это необходимо в целях осуществления законных интересов Администрации сайта или третьей стороны (например, интересов других клиентов), если только такие интересы не перевешиваются Вашими преобладающими законными интересами и правами; или</p>
                    <p>г) когда Вы дали на это свое согласие;</p>
                    <p>д) Администрация сайта обрабатывает персональные данные покупателей клиентов по поручению клиентов, для целей предоставления клиентам возмозможности использовать лицензию и для целей оказания им услуг, связанных с использованием платформы.</p>

                    <p>
                        2.3 Эти цели и основания сбора и обработки Персональных данных определяют и ограничивают виды Персональных данных, которые Администрация сайта собирает, и методы их использования, сроки хранения данных, лиц, обладающих правом на доступ к этим данным, а также то, какие права и другие механизмы контроля доступны Вам в качестве пользователя.
                    </p>

                    <p>2.4 Если Вы просите Администрацию сайта предоставлять услуги в интересах третьей стороны, Вы
                        должны иметь юридическое разрешение третьей стороны раскрыть ее личную информацию
                        Администрации сайта. Это разрешение должно охватывать личную информацию, включая, но не
                        ограничиваясь, имя, адрес, номер телефона, адрес электронной почты, кредитную карту и информацию
                        о доставке третьей стороны. Вы соглашаетесь с тем, что Вы несете все риски, связанные с
                        использованием личной информации третьих лиц, передаваемойВами, и что ни при каких
                        обстоятельствах Администрация сайта не несет никакой ответственности за какую-либо личную
                        информацию или за любые убытки, или ущерб любого рода, понесенные в результате использования
                        любой личной информации третьих лиц. Администрация сайтане несет каких-либо обязательств или
                        ответственности в отношении личной информации какой-либо третьей стороны, переданной Вами.</p>
                    <h5 className="policy__head">3. КАКИЕ ДАННЫЕ АДМИНИСТРАЦИЯ САЙТА СОБИРАЕТ И ОБРАБАТЫВАЕТ</h5>
                    <p className="policy__head-sub">3.1 Основные учетные данные.</p>
                    <p>При настройке аккаунта Администрация сайта запрашивает Ваш адрес электронной почты, Ваше имя,
                        номер мобильного телефона, название Вашего магазина или сайта, вид Вашей деятельности. Ваш адрес
                        электронной почтыи пароль служат основанием для входа на Сайт. Предоставление такой информации
                        необходимо для регистрации аккаунта. При настройке аккаунту автоматически присваивается номер
                        («ID»), который в дальнейшем используется для ссылки на Ваш аккаунт.
                    </p>
                    <p className="policy__head-sub">3.2 Транзакции и платежные данные</p>
                    <p>Для совершения транзакций, Вам необходимо предоставить Администрации сайта платежные данные,
                        чтобы обеспечить возможность проведения транзакции. Если Вы используете для оплаты кредитную
                        карту, Вам необходимо предоставить Администрации сайта информацию о кредитной карте (имя,
                        адрес, номер кредитной карты, дату истечения срока действия и защитный код), которую
                        Администрация сайта обрабатывает и передает оператору платежной системы по Вашему выбору,
                        чтобы обеспечить возможность проведения транзакции и выполнения проверок для борьбы с
                        мошенничеством. Точно так же Администрация сайта будет получать данные от Вашего оператора
                        платежной системы по тем же причинам.
                    </p>

                    <p>Администрация сайта также обрабатывает данные, которые вы загружаете в платформу, в том числе имя, фамилия покупателей, контактные данные покупателей, номер заказа и информацию о содержании заказа покупателя. Обработка таких данных осуществляется по поручению оператора таких персональных  данных в соответствии со статьей 6 Федерального закона №152-ФЗ «О персональных данных».</p>

                    <p className="policy__head-sub">3.3 Другие явно предоставляемые Вами данные.</p>
                    <p>Мы собираем и обрабатываем Персональные данные, которые Вы отправляете Администрации сайта в
                        явной форме (например, на форумах, в чатах, отзывах или других материалах, создаваемых
                        пользователем). Эти данные включают в себя следующее:
                        - сведения, которые Вы публикуете или указываете в комментариях для какого-либо контента и услуг;
                        - сведения, отправляемые в чате;
                        - сведения, которые Вы предоставляете, когда запрашиваете информацию или техническую
                        поддержку либо приобретаете контент и услуги, включая сведения, необходимые для обработки
                        Ваших заказов с применением соответствующего механизма оплаты;
                        - сведения, которые Вы предоставляете, участвуя в конкурсах, лотереях, акциях или опросах,
                        например, Ваши контактные данные.
                    </p>
                    <p className="policy__head-sub">3.4 Использование сайта.</p>
                    <p>Администрация сайта собирает различную информацию при Вашем взаимодействии с сайтом,
                        контентом и услугами, предлагаемыми Администрацией сайта.
                        К Персональным данным, которые Администрация сайта может собирать, относятся, помимо
                        прочих, сведения о браузере и устройстве, данные, собираемые при автоматизированном
                        электронном взаимодействии, и данные об использовании приложений.
                        Кроме того, Администрация сайта будет отслеживать Ваши действия на сайтах и в приложениях,
                        чтобы убедиться, что Вы не робот, и оптимизировать услуги сайта.
                    </p>
                    <p className="policy__head-sub">3.5 Отслеживание данных и cookie-файлы.</p>
                    <p>Администрация сайта использует cookie-файлы, представляющие собой текстовые файлы,
                        размещаемые на Вашем компьютере, которые помогают Администрации сайта анализировать, как
                        пользователи используют услуги сайта, и аналогичные технологии (например, веб-маяки,
                        идентификаторы устройства, пиксельные и рекламные теги), чтобы распознавать Вас и/или
                        подключение Ваших устройств, когда Вы используете различные устройства и услуги. Кроме того, эти
                        данные помогают Администрации сайта улучшать предлагаемые услуги, маркетинг, аналитику или
                        функциональные возможности сайта. Использование cookie-файлов является стандартной практикой в
                        Интернете. Хотя большинство веб-браузеров автоматически принимают cookie-файлы, окончательное
                        решение, принимать их или нет, остается за Вами. Вы можете настроить параметры браузера, чтобы не
                        допускать прием cookie-файлов или получать уведомление при каждой отправке Вам cookie-файла. Вы
                        можете запретить использование cookie-файлов, выбрав соответствующие параметры в веб-браузере.
                        Однако обратите внимание, что в этом случае Вы не сможете пользоваться всеми функциональными
                        возможностями сайта.</p>
                    <p>Когда Вы посещаете какую-либо из служб сайта, серверы Администрации сайта регистрируют в
                        журнале Ваш глобальный IP-адрес, представляющий собой номер, который автоматически назначается
                        для сети, частью которой является Ваш компьютер.</p>
                    <p className="policy__head-sub">3.6 Сервис Google Analytics Яндекс.Метрика</p>
                    <p>Сайтиспользует Google Analytics и Яндекс.Метрика — сервисы веб-аналитики. GoogleAnalytics и
                        Яндекс.Метрика используют «cookie-файлы» — текстовые файлы, размещаемые на компьютерах
                        посетителей, которые помогают операторам веб-сайта анализировать, как посетители пользуются
                        сайтом. Информация об использовании веб-сайта посетителями, созданная с помощью cookie-файлов,
                        как правило, передается и хранится на серверах компании Google и Yandex.</p>
                    <p>Компания Google и Yandex не будут связывать IP-адрес, передаваемый в контексте GoogleAnalytics и
                        Яндекс.Метрика, с какими-либо другими данными, хранящимися в Google и Yandex. Вы можете
                        запретить использование cookie-файлов, выбрав соответствующие параметры в веб-браузере. Однако
                        обратите внимание, что в этом случае Вы не сможете пользоваться полной функциональностью сайта.</p>
                    <p>Более того, пользователи могут запретить сбор данных о том, как они используют сайт (включая
                        данные об IP-адресе), осуществляемый с помощью cookie-файлов.</p>
                    <p className="policy__head-sub">3.7 Рекомендации контента.</p>
                    <p>Администрация сайта может обрабатывать информацию, собираемую в соответствии с Политикой
                        конфиденциальности, для того чтобы адаптировать согласно Вашим предпочтениям контент, продукты
                        и сервисы, показываемые на страницах сайта и в сообщениях об обновлениях, и
                        отображать в них соответствующие рекомендации и предложения. Это делается в целях повышения
                        уровня Вашего обслуживания.</p>
                    <p>В соответствии с Вашим согласием или в случаях, когда это явно разрешено действующими законами
                        об электронных рассылках, Администрация сайта может посылать Вам на Ваш адрес электронной
                        почты маркетинговые сообщения о продуктах и услугах, предлагаемых Администрацией сайта. В таком
                        случае Администрация сайта также может использовать Вашу собранную информацию для настройки
                        таких маркетинговых сообщений, а также собирать информацию о том, открывали ли Вы сообщения и
                        на какую ссылку в тексте Вы нажимали.</p>
                    <p>Вы можете в любой момент отменить или отозвать свое согласие на получение маркетинговых
                        электронных сообщений, отозвав свое согласие на той же странице, на которой Вы ранее подтвердили
                        свое согласие, или нажав на ссылку «Отказаться от подписки», которая имеется в каждом
                        маркетинговом сообщении.</p>
                    <p className="policy__head-sub">3.8 Информация, необходимая для выявления нарушений.</p>
                    <p>Администрация сайта собирает определенные данные, которые необходимы для выявления,
                        расследования и предотвращения мошенничества и прочих нарушений и действующего
                        законодательства. Такие данные используются только в целях выявления, расследования и
                        предотвращения нарушений и, в соответствующих случаях, принятия мер при таких нарушениях, и
                        хранятся в течение минимального времени, необходимого для этих целей. Если эти данных указывает
                        на то, что произошло нарушение, Администрация сайта продолжит хранить такие данные для
                        установления, осуществления или защиты юридических требований в течение применимого срока
                        давности или до тех пор, пока не будет принято судебное решение. Обратите внимание, что конкретные
                        данные, хранящиеся для этой цели, не будут Вам раскрыты в случае, если таковое раскрытие
                        информации поставит под угрозу механизм, применяемый Администрацией сайта для выявления,
                        расследования и предотвращения таких нарушений.
                    </p>
                    <h5 className="policy__head">4. СРОК ХРАНЕНИЯ ДАННЫХ</h5>
                    <p>Если иное не предусмотрено законом Администрация прекращает обработку Ваших персональных данных:
                    </p>

                    <p>
                        после достижении цели, для которой осуществлялась обработка персональных данных;
                    </p>
                    <p>
                        после отзыва субъекта персональных данных согласия на обработку персональных данных;
                    </p>
                    <p>
                        прекращение предоставление лицензии на использование платформы клиенту и/или оказания сопутствующих услуг и расторжение соответствующего договора/соглашения с клиентом;
                    </p>
                    <p>
                        после получения соответствующего требования субъекта персональных данных о необходимости прекратить обработку его персональных данных;
                    </p>

                    <p>
                        Смотря что произойдет раньше.
                    </p>

                    <p>
                        Для целей прекращений обработки персональных данных Администрация сайта блокирует или уничтожает персональные данные, если иное не предусмотрено законом.
                    </p>

                    <p>В частности,</p>
                    <p>- Если Вы закрываете свой аккаунт, Ваши Персональные данные отмечаются для удаления, за
                        исключением случаев, когда юридические требования или другие преобладающие законные цели
                        требуют более длительного хранения.</p>
                    <p>- Если Вы отзываете свое согласие на обработку Ваших Персональных данных или Персональных
                        данных Вашего ребенка, Администрация сайта удаляет Ваши Персональные данные или,
                        соответственно, Персональные данные Вашего ребенка без неоправданной задержки в той мере, в
                        какой сбор и обработка персональных данных основаны на отзыве согласия.</p>
                    <p>- Если Вы используете право на возражение против обработки Ваших Персональных данных,
                        Администрация сайта рассматриваем Ваше возражение и без неоправданной задержки удаляет Ваши
                        Персональные данные, обрабатываемые с целью, против которой Вы возражаете, если нет иной
                        правовой основы для обработки и хранения этих данных или сохранение этих данных не требуется в
                        соответствии с действующим законодательством.</p>
                    <p>- Обратите внимание, что Администрация сайта может хранить определенные транзакционные данные
                        в соответствии с установленными законом коммерческими и налоговыми правилами сроком до десяти
                        (10) лет.
                    </p>
                    <h5 className="policy__head">5. КТО ИМЕЕТ ДОСТУП К ДАННЫМ</h5>
                    <p>5.1 Администрация сайта и ее поставщики услуг (в том числе, платежные каналы связи) могут
                        предоставлять друг другу доступ к Вашим Персональным данным и использовать их по мере
                        необходимости для достижения целей, указанных выше в Политике конфиденциальности. В
                        случае реорганизации, продажи или слияния Администрация сайта может передать Персональные
                        данные соответствующей третьей стороне в соответствии с действующим законодательством.
                    </p>
                    <p>5.2 Администрация сайта также предоставляет Ваши Персональные данные сторонним поставщикам
                        услуг, которые предоставляют услуги по поддержке клиентов в связи с товарами, контентом и
                        услугами, распространяемыми через сайт. Ваши Персональные данные будут использоваться в
                        соответствии с настоящей Политикой конфиденциальности и только в той мере, которая необходима
                        для предоставления услуг по поддержке клиентов.
                    </p>
                    <p>5.3 В соответствии со стандартами Интернета Администрация сайта также имеем право предоставлять
                        определенную информацию (включая Ваш IP-адрес и идентификацию контента, к которому Вы хотите
                        получить доступ) сторонним поставщикам сетевых услуг, которые предоставляют услуги сети доставки
                        контента и серверов.</p>
                    <p>5.4 На сайте имеются доски объявлений, каталоги, форумы и/или чаты, где пользователи могут
                        обмениваться мнениями и общаться друг с другом. Обратите внимание, что после публикации
                        сообщения на доске, каталоге, форуме или в чате, это сообщение становиться общедоступным в
                        Интернете, и Вы сознаете, что совершаете это на свой страх и риск. Если Ваши Персональные данные
                        опубликованы на одном из разделов сайта вопреки Вашему желанию, воспользуйтесь поддержкой
                        сайта, чтобы запросить их удаление.</p>
                    <p>5.5 Администрация сайта может разрешить Вам связать Ваш аккаунт с аккаунтом, предложенным
                        третьей стороной (facebook.com, mail.google.com и пр.). В случае Вашего согласия на связывание
                        аккаунтов Администрация сайта может собирать и объединять информацию, которую Вы разрешили
                        Администрации сайта получать от третьей стороны, с информацией о Вашем аккаунте в той степени,
                        насколько это допускается Вашим согласием на данный момент. Если для связывания аккаунтов
                        требуется передать Вашу личную информацию от Администрации сайта третьей стороне, Вы будете
                        информированы об этом до связывания аккаунтов и Вам будет предоставлена возможность дать
                        согласие на связывание аккаунтов и передачу Вашей информации. Использование Вашей информации
                        третьей стороной регламентируется политикой конфиденциальности этой третьей стороны, которую
                        Администрация сайта рекомендует тщательно изучить.</p>
                    <p>5.6 Администрация сайта может раскрывать Персональные данные в соответствии с судебными
                        распоряжениями или правилами, которые обязывают раскрывать такую информацию.</p>
                    <h5 className="policy__head">6. ВАШИ ПРАВА И МЕХАНИЗМЫ КОНТРОЛЯ</h5>
                    <p>Законы о защите данных в Российской Федерации, в Европейской экономической зоне и на других территориях предоставляют
                        своим гражданам определенные права в отношении их Персональных данных. Хотя другие
                        юрисдикции могут предоставлять своим гражданам меньше прав, Администрация сайта создает
                        инструменты, предназначенные для реализации прав своих клиентов по всему миру.
                    </p>

                    <p>
                        В соответствии с законодательством Российской Федерации вы имеете следующие права:
                    </p>

                    <p>
                        а) право на получение сведений об обработке персональных данных, для этого необходимо направить Администратору сайта соответствующий запрос, требования к которому предусмотрены в законодательстве Российской Федерации;
                    </p>
                    <p>б) право уточнить персональные данные: потребовать внести изменения, дополнения, исправления в обрабатываемые Администратором сайта данные;</p>
                    <p>
                        в) право потребовать прекратить обработку персональных данных, которая по мнению субъекта является неправомерной;
                    </p>
                    <p>
                        г) право отозвать согласие на обработку персональных данных, данное Администратором сайта;
                    </p>
                    <p>
                        д) иные права, предусмотренные законодательством Российской Федерации.
                    </p>


                    <p>Если вы являетесь постоянным жителем Европейской экономической зоны, Вы имеете следующие права в
                        отношении своих Персональных данных:
                    </p>
                    <p className="policy__head-sub">6.1 Право на доступ.</p>
                    <p>Вы имеете право на доступ к своим Персональным данным, которые у хранятся у Администрации
                        сайта, т. е. право бесплатно востребовать 1) информацию о том, хранятся ли Ваши Персональные
                        данные, 2) получение доступа и/или 3) дубликатов хранящихся Персональных данных. Вы можете
                        использовать это право на доступ к своим Персональным данным посредством сайта.</p>
                    <p className="policy__head-sub">6.2 Право на исправление.</p>
                    <p>Обрабатывая Ваши Персональные данные, Администрация сайта будет принимать соответствующие
                        меры для поддержки точности и актуальности Ваших Персональных данных в целях, для которых
                        осуществляется их сбор. Если Ваши Персональные данные являются неточными или неполными, Вы
                        можете их изменить в соответствующем разделе сайта.
                    </p>
                    <p className="policy__head-sub">6.3 Право на стирание.</p>
                    <p>Вы имеете право потребовать удалить Ваши Персональные данные, удалив Ваш аккаунт.</p>
                    <p>В результате удаления аккаунта Вы потеряете доступ к службам сайта, в том числе к аккаунту,
                        подпискам и другой информации, связанной с аккаунтом, а также возможность доступа к другим
                        услугам, для которых используется аккаунт.
                    </p>
                    <p className="policy__head-sub">6.4 Право на возражение.</p>
                    <p>Там, где обработка Персональных данных основывается исключительно на законных интересах в
                        соответствии со статьей 6(1 )(f) GDPR / настоящей Политикой конфиденциальности, Вы можете
                        использовать свое право на возражение. Если вы выразите свое возражение, Администрация сайта не
                        обрабатывает Ваши Персональные данные, когда нет убедительных и преобладающих законных
                        оснований для такой обработки, как описано в статье 21 GDPR; в частности, когда данные необходимы
                        для установления, осуществления или защиты юридических требований.</p>
                    <p>Вы также имеете право подать жалобу в контролирующий орган.</p>
                    <h5 className="policy__head">7. ДЕТИ.</h5>
                    <p>Минимальный возраст для создания аккаунта составляет 16 лет. Администрация сайта сознательно не
                        собирает Персональные данные у детей младше этого возраста. В некоторых странах установлен
                        больший возраст согласия на сбор Персональных данных, поэтому Администрация сайта требует
                        родительское согласие перед созданием аккаунта и связанным с ним сбором Персональных данных.
                        Администрация сайта призывает родителей давать своим детям инструкции о том, что никогда не
                        следует открывать свои персональные данные в сети интернет.</p>
                    <h5 className="policy__head">8. ССЫЛКИ НА ДРУГИЕ САЙТЫ.</h5>
                    <p>Сайт может содержать ссылки на другие веб-сайты. Ссылка на веб-сайт третьей стороны не означает,
                        что Администрация сайта одобряет ее или что Администрация сайта с ней связана. Администрация
                        сайта не контролирует сторонние веб-сайты. Вы обращаетесь к таким сторонним веб-сайтам или
                        контенту на свой страх и риск. Вы должны всегда читать политику конфиденциальности стороннего
                        веб-сайта, прежде чем предоставлять какую-либо информацию на веб-сайт.</p>
                    <h5 className="policy__head">9. ИЗМЕНЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ.</h5>
                    <p>Любые изменения, которые Администрация сайта может внести в Политику конфиденциальности в
                        будущем, будут опубликованы на этой странице, и если необходимо или в случае внесения
                        существенных изменений в данную политику, Администрация сайта уведомит Вас об этих изменениях
                        через Сайт. Ожидается, что Вы будете время от времени повторно посещать
                        настоящую страницу, чтобы узнавать о любых изменениях, которые Администрация сайта вносит,
                        поскольку они являются юридически обязательными для Вас.</p>
                    <h5 className="policy__head">10. КОНТАКТНАЯ ИНФОРМАЦИЯ</h5>
                    <p>Вы можете связаться с ответственным специалистом Администрации сайта по защите данных по
                        указанному ниже адресу.</p>
                    <p>Администрация сайта рассматривает любые запросы, отправленные по электронной почте
                        или мобильной связи: <br/> e-mail: <a href="mailto:support@r-lab.biz">support@r-lab.biz</a>
                        телефон <a href="tel:8-800-555-17-43">8-800-555-17-43</a>
                    </p>
                    <p className="policy__head policy_bold">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «Стрекоза»</p>
                    <p>
                        ОГРН: 1217700197934 <br/>
                        ИНН: 7716956287 <br/>
                        КПП: 771601001 <br/>
                        129327, г. Москва, ул. Коминтерна, д. 20/2, этаж 1, помещение/комната/ рабочее место 1/3/11а
                    </p>
                    <p className="policy__head-sub">Контакты для связи с Администрацией Сервиса</p>
                    <p>
                        e-mail: <a href="mailto:support@strekoza.ai">support@r-lab.biz</a> <br/>
                        телефон: <a href="tel:8-800-555-17-43">8-800-555-17-43</a><br/>
                        Дата публикации: <br/>
                        20.01.2022 г.
                    </p>
                </div>
            </section>
        </Layout>
    )

}

